import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/_services/loader.service';

@Component({
  selector: 'app-validation-section',
  templateUrl: './validation-section.component.html',
  styleUrls: ['./validation-section.component.scss']
})
export class ValidationSectionComponent implements OnInit {

  souscription;

  constructor(private loaderService:LoaderService) { }

  ngOnInit() {
    this.souscription = this.loaderService.getSouscription();
  }

  goBack() {
    window.location.href = "https://assurances-factures-oney.souscription.eu/?nom=" 
                          + this.souscription.nom
                          + "&Civilite=" + this.souscription.civilite
                          + "&prenom=" + this.souscription.prenom
                          + "&ddn=" + this.souscription.dateNaissance
                          + "&ldn=" + this.souscription.lieuNaissance
                          + "&email=" + this.souscription.email
                          + "&adresse=" + this.souscription.adresse
                          + "&ville=" + this.souscription.ville
                          + "&cp=" + this.souscription.codePostal
                          + "&tel=" + this.souscription.mobile
                          + "#formulaire";
  }

}
