import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({ providedIn: 'root' })

export class MessengerService {

    constructor(private http: HttpClient) { }

    // Device key : the key of the trusted device
    message(step:number, data: FormData) {
        return this.http.post<any>(`http://127.0.0.1:8000/digitalisation/` + step, data);
    }

}