import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from 'src/app/_services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  
  uid: string;

  constructor(private loaderService:LoaderService, private route:ActivatedRoute, private router:Router) {
    this.uid = this.route.snapshot.params.uid;
  }


  ngOnInit() {
    this.loaderService.load(this.uid).subscribe(() => this.router.navigate(['/souscription/'+ this.uid +'/validation']));
  }

}
