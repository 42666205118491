import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sepa-section',
  templateUrl: './sepa-section.component.html',
  styleUrls: ['./sepa-section.component.scss']
})
export class SepaSectionComponent implements OnInit {

  iban;
  bic;
  date;
  isAccept;
  
  constructor() { }

  ngOnInit() {
  }

}
