import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Souscription } from '../_models/Souscription';
import { map } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })

export class LoaderService {

    souscription;

    constructor(private http: HttpClient) { }

    load(uid: string) {
        return this.http.get<any>(`https://www.soteria-spbfamily.eu:1070/souscription/` + uid).pipe(map((s) => this.souscription = s))
    }

    getSouscription(uid?:string) {
        if (!this.souscription && uid)
            this.load(uid)
            
        return this.souscription;
    }

}