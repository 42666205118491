import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contrats',
  templateUrl: './contrats.component.html',
  styleUrls: ['./contrats.component.scss']
})
export class ContratsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
