import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { LoaderService } from 'src/app/_services/loader.service';
import { SepaSectionComponent } from '../sepa-section/sepa-section.component';

@Component({
  selector: 'app-modalite-paiement-section',
  templateUrl: './modalite-paiement-section.component.html',
  styleUrls: ['./modalite-paiement-section.component.scss']
})
export class ModalitePaiementSectionComponent implements OnInit {

  isMensuel: boolean = null;
  error: string;
  souscription;
  loading: boolean = false;

  @ViewChild(SepaSectionComponent, null) sepaSectionComponent: SepaSectionComponent;

  constructor(private httpClient: HttpClient, private loaderService: LoaderService) {
    this.souscription = this.loaderService.getSouscription()
  }

  ngOnInit() {
  }

  next() {
   

    if (!this.sepaSectionComponent.isAccept) {
      this.error = "Veuillez remplir tous les champs requis et accepter de démarrer les garanties pour pouvoir poursuivre la souscription.";
      return;
    }

    this.error = "";
    this.loading = true;

    let data = new FormData();
    data.append('iban', this.sepaSectionComponent.iban);
    data.append('bic', this.sepaSectionComponent.bic);
    data.append('date', this.sepaSectionComponent.date);
    data.append('isMensuel', this.isMensuel + '');
    data.append('uid', this.souscription.uid);

    this.httpClient.post<any>(`https://www.soteria-spbfamily.eu:1070/souscription/modalite`, data).subscribe(
      (success) => { 
        window.location.href = success.url;
        this.loading = false;
      },
      (error) => {
        this.error = error.error.error;
        this.loading = false;
      }
    );
  }

}
