import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { fadeInAnimation } from '../../_animations/fade';
import { MessengerService } from '../../_services/messenger.service'
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-messenger',
  templateUrl: './messenger.component.html',
  styleUrls: ['./messenger.component.scss'],
  animations: [fadeInAnimation],
  host: { '[@fadeInAnimation]': '' }
})
export class MessengerComponent implements OnInit {


  constructor(private messengerService: MessengerService, private router: Router) { }

  @Input() messages: any[];

  host: string = 'http://protect-habitat.souscription.eu';
  product: number;
  firstname: string;
  lastname: string;
  email: string;
  birthdate: string;
  hometown: string;
  mobile: string;
  address: string;
  zipcode: string;
  city: string;
  uid: string;
  error: string;
  step: number = 0;
  authorized: boolean = false;

  @ViewChild('scroll', { static: true }) private myScrollContainer: ElementRef;

  ngOnInit() {
    if(top.location !== self.location)
      addEventListener("message", (e) => {
        if (!e.data || e.data === '' || e.data.type === 'webpackOk')
          return;
      
        this.authorized = (e.origin == this.host && e.data === 'oiIzBL7a4fKLIyIjYS5o')
      }, false);

    this.messages = [];
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }

  goToGoogle() {
    top.location.href = 'http://www.google.com'
    // top.postMessage('http://www.google.com', this.host)
  }

  backTo(step: number) {
    this.messages.push({
      'from': 'mine',
      'content': 'Je voudrais modifier ma réponse',
      'last': true
    })

    this.messages.push({
      'from': 'yours',
      'content': 'Aucun soucis 😄 ',
      'last': true
    })
    this.step = step;


  }

  ngChoice(choice: number) {

    switch (choice) {
      case 1:
        this.product = 1;

        this.messages.push({
          'from': 'mine',
          'step': this.step,
          'content': 'Je souscris à Protect Habitat à 13,90 €',
          'last': true
        })

        this.messages.push({
          'from': 'yours',
          'content': 'Faisons plus ample connaissance 😄 <br/> Quels sont votre nom, prénom et adresse mail svp ?',
          'last': true
        })
        break;

      case 2:
        this.product = 2;

        this.messages.push({
          'from': 'mine',
          'step': this.step,
          'content': 'Je souscris à Protect Facture à 5,50 €',
          'last': true
        })

        this.messages.push({
          'from': 'yours',
          'content': 'Faisons plus ample connaissance 😄 <br/> Quels sont votre nom, prénom et adresse mail svp ?',
          'last': true
        })
        break;

    }



    this.step = 1;
  }

  reply(value?: any) {
    let data: FormData = new FormData();

    switch (this.step) {
      case 1:

        this.messages.push({
          'from': 'mine',
          'step': this.step,
          'content': "Je m'appelle " + this.firstname + " " + this.lastname,
          'last': false
        }, {
          'from': 'mine',
          'step': this.step,
          'content': "Mon adresse courriel est " + this.email,
          'last': true
        })

        data.append('product', this.product.toString());
        data.append('firstname', this.firstname);
        data.append('lastname', this.lastname);
        data.append('email', this.email);
        break;

      case 2:
        this.messages.push({
          'from': 'mine',
          'content': "Je suis né le  " + this.birthdate + " à " + this.hometown,
          'last': true
        })

        data.append('uid', this.uid);
        data.append('birthdate', this.birthdate);
        data.append('hometown', this.hometown);
        break;

      case 3:
        this.messages.push({
          'from': 'mine',
          'step': this.step,
          'content': "Mon numéro de téléphone est : " + this.mobile,
          'last': true
        })

        data.append('uid', this.uid);
        data.append('mobile', this.birthdate);
        break;

      case 4:
        this.messages.push({
          'from': 'mine',
          'step': this.step,
          'content': "Mon adresse est : " + this.address + " " + this.zipcode + " " + this.city,
          'last': true
        })

        data.append('uid', this.uid);
        data.append('mobile', this.birthdate);
        break;

      case 5:
        if (value)
          this.messages.push({
            'from': 'mine',
            'step': this.step,
            'content': 'Je suis locataire',
            'last': true
          })
        else
          this.messages.push({
            'from': 'mine',
            'step': this.step,
            'content': 'Je suis propriétaire',
            'last': true
          })

        data.append('uid', this.uid);
        data.append('isLocataire', value);
        break;

      case 6:
        if (value)
          this.messages.push({
            'from': 'mine',
            'step': this.step,
            'content': 'Oui, je suis salarié.e',
            'last': true
          })
        else
          this.messages.push({
            'from': 'mine',
            'step': this.step,
            'content': 'Non, je ne suis pas salarié.e',
            'last': true
          })

        data.append('uid', this.uid);
        data.append('isSalarie', value);
        break;

      case 7:
        if (value)
          this.messages.push({
            'from': 'mine',
            'step': this.step,
            'content': 'Oui',
            'last': true
          })
        else
          this.messages.push({
            'from': 'mine',
            'step': this.step,
            'content': 'Non',
            'last': true
          })

        data.append('uid', this.uid);
        data.append('isHandicape', value);
        break;

      case 8:
        this.messages.push({
          'from': 'mine',
          'step': this.step,
          'content': 'J\'accepte que la gestion de ma souscription et de mon contrat s\'exerce de façon dématérialisée',
          'last': true
        })

        setTimeout(() => {
          this.router.navigate(['contrat']);
        }, 1000);

        break;
    }

    this.messengerService.message(this.step, data)
      .subscribe(
        (data) => {
          this.error = "";
          this.uid = data.uid;
          this.step = parseInt(data.step);

          data.messages.forEach((message, key, arr) => {
            let isLast = (Object.is(arr.length - 1, key))

            this.messages.push({
              'from': 'yours',
              'content': message,
              'last': isLast
            })

            this.scrollToBottom();
          })


        },
        (err) => {

          err.error.messages.forEach((message, key, arr) => {
            let isLast = (Object.is(arr.length - 1, key))

            this.messages.push({
              'from': 'yours',
              'content': message,
              'last': isLast
            })
            this.scrollToBottom();
          })

        }
      )
  }

}