import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './_pages/home/home.component';
import { MessengerComponent } from './_components/messenger/messenger.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import {MatTooltipModule} from '@angular/material/tooltip';
import { ContratsComponent } from './contrats/contrats.component';
import { SouscriptionComponent } from './_pages/souscription/souscription.component';
import { HeaderComponent } from './_components/header/header.component';
import { BreadcrumbComponent } from './_components/breadcrumb/breadcrumb.component';
import { StepsComponent } from './_components/steps/steps.component';
import { ValidationSectionComponent } from './_sections/validation-section/validation-section.component';
import { DocumentsSectionComponent } from './_sections/documents-section/documents-section.component';
import { ModalitePaiementSectionComponent } from './_sections/modalite-paiement-section/modalite-paiement-section.component';
import { SepaSectionComponent } from './_sections/sepa-section/sepa-section.component';
import { RecapulatifSectionComponent } from './_sections/recapulatif-section/recapulatif-section.component';
import { LoaderComponent } from './_middleware/loader/loader.component';
import { ResultSectionComponent } from './_sections/result-section/result-section.component';
import { MaterialModule } from './materials.module';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MessengerComponent,
    ContratsComponent,
    SouscriptionComponent,
    HeaderComponent,
    BreadcrumbComponent,
    StepsComponent,
    ValidationSectionComponent,
    DocumentsSectionComponent,
    ModalitePaiementSectionComponent,
    SepaSectionComponent,
    RecapulatifSectionComponent,
    LoaderComponent,
    ResultSectionComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    MatTooltipModule,
    MaterialModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
