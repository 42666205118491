import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { LoaderService } from 'src/app/_services/loader.service';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss']
})
export class StepsComponent implements OnInit {

  product:string;
  step : number;
  uid: string;
  constructor(private router: Router, private loaderService:LoaderService, private route:ActivatedRoute) {
    this.uid = this.route.snapshot.params.uid;
   }

  ngOnInit() {
    this.product = (this.loaderService.getSouscription())? this.loaderService.getSouscription().product : '';

    if(this.loaderService.getSouscription(this.uid)) {
      this.makeSwitch()
      this.router.events.subscribe(()=>this.makeSwitch())
    } else if(this.step < 4) {
       window.location.href = 'https://assurances-factures-oney.souscription.eu';
    }
    this.makeSwitch()
  }

  makeSwitch() {
    switch(this.router.url.split('/')[3]) {
      case 'validation':
        
        this.step = 1;
      break;
      
      case 'documents':
        this.step = 2;
      break;

      case 'modalites':
        this.step = 3;
      break;

      case 'recapulatif':
        this.step = 4;
      break;

      default:
        this.step = 0;
    }
  }

}
