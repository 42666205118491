import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from 'src/app/_services/loader.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-documents-section',
  templateUrl: './documents-section.component.html',
  styleUrls: ['./documents-section.component.scss']
})
export class DocumentsSectionComponent implements OnInit {

  isReadDocs: boolean;
  error: string;
  souscription;
  blob:any;
  url:SafeResourceUrl;
  uid:string;
  isBrowserNotCompatible:boolean;
  filename:string;

  constructor(private http:HttpClient, private sanitizer: DomSanitizer, private httpClient:HttpClient, private loaderService:LoaderService, private router:Router, private route:ActivatedRoute) { 
    this.souscription = this.loaderService.getSouscription();
    this.uid = this.route.parent.snapshot.params.uid;
    this.isBrowserNotCompatible = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    this.filename = "documents-information.pdf";
  }

  ngOnInit() {
    this.downloadFile().subscribe(
      (response) => {
        this.blob = new Blob([response], { type: 'application/pdf' });
        this.url= this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(this.blob));

        if(this.isBrowserNotCompatible)
          window.navigator.msSaveOrOpenBlob(this.blob, this.filename); 

      },
      () => {}
    )
  }

  downloadBlob() {
    if(this.isBrowserNotCompatible)
      window.navigator.msSaveOrOpenBlob(this.blob, this.filename);
    else
      window.open(window.URL.createObjectURL(this.blob), "_blank");
  }

  downloadFile() {		 
		return this.http.get('https://www.soteria-spbfamily.eu:1070/document/' + this.uid, { responseType: 'blob' });
  }

  next() {
    if(!this.isReadDocs) {
      this.error = "Ce champ est requis.";
      return;
    }

    let data = new FormData();
    data.append('readdocs', this.isReadDocs + '')
    data.append('uid', (this.souscription)? this.souscription.uid : null)
    this.httpClient.post<any>(`https://www.soteria-spbfamily.eu:1070/souscription/readdocs`, data).subscribe(
      (success) => this.router.navigate(['/souscription/'+this.souscription.uid+'/modalites']),
      (error) => { this.error = "Un problème est survenu"; console.log(error)},
    );
  }

  isCompatibleBrowser() {
   return  
  }
}
