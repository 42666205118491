import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from 'src/app/_services/loader.service';

@Component({
  selector: 'app-recapulatif-section',
  templateUrl: './recapulatif-section.component.html',
  styleUrls: ['./recapulatif-section.component.scss']
})
export class RecapulatifSectionComponent implements OnInit {

  souscription;
  uid;

  constructor(private loaderService:LoaderService, private route:ActivatedRoute) {
    this.uid = this.route.snapshot.parent.params.uid;
   }

  ngOnInit() {
    this.loaderService.load(this.uid).subscribe(
      souscription => this.souscription = souscription
    )
  }

  toOney() {
    window.location.href = 'https://www.oney.fr';
  }
}
